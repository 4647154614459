import general from '../inc/general';

import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;


(function($ , dataLayer) {

    window.getparam = window['getparam'] ||
    function (name) {
        var re = new RegExp("[&\?]" + name + "=([^&]*)", "i");
        var m = document.location.search.match(re);
        return (m != null && m.length > 1) ? m[1] : "";
    };

    let initchat = false;

    window.windowOnLoad = function (callback) {
        if (window.addEventListener) {
            window.addEventListener('load', callback, false);
        } else if (window.attachEvent) {
            window.attachEvent('onload', callback);
        }
    };

    window.savecookie = function (a, b, c) {
        var d = new Date();
        d.setTime(d.getTime() + 864E5 * ("undefined" != typeof c ? c : 3));
        c = "; expires\x3d" + d.toGMTString();
        document.cookie = a + "\x3d" + b + c + "; path\x3d/;domain\x3d" + document.domain.replace(/^[^\.]+/, '')
    };

    window.readcookie = function (a) {
        a = new RegExp("(?:^| )" + a + "\x3d([^;]*)", "i");
        return (a = document.cookie.match(a)) && 2 == a.length ? a[1] : null;
    };


    window.openLightBox = function (name, type, length, url) {
        $('#totalPage').addClass('totalOn').fadeTo(260, 0.5, function () {
            $('#' + name + '').fadeIn(600);
        });
        if (type === 'timeout') setTimeout(function () {
            closeLightBox(name)
        }, length);
        if (type === 'redirect') setTimeout(function () {
            window.location.href = url;
        }, length);
        if (type === 'loadframe') {
            $('#' + name + '').load(url);
        }
    };

    window.closeLightBox = function (name, callback) {
        $('#' + name + '').fadeOut(300, function () {
            $('#totalPage').fadeTo(500, 0, function () {
                $(this).removeClass('totalOn');
                if (typeof (callback) == "function") callback();
            });
        });
    };


    window.loadPrivacyLinkA = function (url) {
        let cpurl = url ? url : "/legal/cookie-policy.html";
        $('#pp_agreement').find('#pp_content').load(cpurl, function () {
            window.openLightBox("pp_agreement");
        });
    };

    window.geoipcookie = window.readcookie('geoip');

    function replaceLoginUrl(){
        $('.btn-login').attr("href", "//trader.fihtrader.com/webpl3/Account/Login/Lang/Arabic");
    }

    function replaceLangUrl(){
        $('.sa-link').attr("href", "//www.iforexsa.com");
    }
    
    if (window.geoipcookie === 'SA'){
        replaceLoginUrl();
        replaceLangUrl();
    }
   

    window.scrollingTasks = [];


    window.__dlw = {
        rc: function (e) {
            var t = new RegExp("(?:^| )" + e + "=([^;]*)", "i"),
                i = document.cookie.match(t);
            return i && 2 === i.length ? decodeURIComponent(i[1]) : null;
        },
        sc: function (e, t, i) {
            var n = "";
            if (i) {
                var l = new Date();
                l.setTime(l.getTime() + 24 * (void 0 !== i ? i : 3) * 60 * 60 * 1e3), n = "; expires=" + l.toGMTString()
            }
            document.cookie = e + "=" + encodeURIComponent(t) + n + "; path=/;";
        },
        store: function (e, t, i) {
            i = i || 3;
            var n = {};
            if (n[e] = t, window.dataLayer.push(n), 3 !== i) {
                var l = window.__dlw.rc("cdl" + i),
                    r = [];
                if (null != l)
                    for (var c = (r = l.split(";")).length; c--;)
                        if (r[c].split(":")[0] === e) {
                            r.splice(c, 1);
                            break;
                        }
                r.push(e + ":" + t.replace(/;/g, ""));
                window.__dlw.sc("cdl" + i, r.join(";"), 1 == i && 1e3);
            }
        },
        init: function () {
            var e = (window.__dlw.rc("cdl1") || "") + ";" + (window.__dlw.rc("cdl2") || "");
            if (";" !== e) {
                let n;
                for (let t = e.split(";"), n = {}, i = t.length; i--;) "" !== t[i] && (n[t[i].split(":")[0]] = t[i].split(":")[1]);
                window.dataLayer.push(n);
            }
        }
    };

    window.jQuery('.btn-send').on('click', function(){
        
        var name = $('.mail_popup_name').val();

        var subject = $('.mail_popup_subject').val();

        var text = $('.mail_popup_textarea').val();

        if (subject != ''){
        window.location = 'mailto:salesgroup@iforex.com?subject=' + subject + '&body=Name:' + name + '%0D%0A%0D%0A' + text;

        closeLightBox("mail_popup");
        $('#mail_popup input, #mail_popup textarea').val('');
        $('.mail_popup_subject').css('border','none');
        }else{
            $('.mail_popup_subject').css('border','1px solid red');
        }


    });


    window.jQuery('.toggle-title').on('click', function () {


        if (!$(this).parent().hasClass('active')) {
            $('.toggle-content').slideUp();
            $('.toggle-pannel').removeClass('active');
            $(this).parent().find('.toggle-content').slideDown();
            $(this).parent().addClass('active');

        } else {
            $('.toggle-content').slideUp();
            $('.toggle-pannel').removeClass('active');
        }

    });

    window.isInViewport = function (elem) {
        let elementTop = elem.offset().top;
        let elementBottom = elementTop + elem.outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        //return elementBottom > viewportTop && elementTop < viewportBottom;
        return elementTop < viewportBottom;
    };

    /* add/remove class active for nav */
    function setActiveUrl() {
        let urlLocation = $('body').data('page-name');
        $('.header-top ul li').find('active').removeClass('active');

        switch (urlLocation) {
            case "hp" :
                $('.home-url.main-link').addClass('active');
                break;
            case "getting-started" :
            case "education-center" :
                $('.gs-url.main-link').addClass('active');
                break;
            case "education-package":
            case "pro-package":
            case "friend-bring-friend":
                $('.promotions-url.main-link').addClass('active');
                break;
            case "stocks":
            case "fixed-interest-account":
            case "economic-calendar":
            case "trading-tools":
            case "trading-platforms":
            case "live-rates":
            case "currencies-hub":
            case "commodities-hub":
            case "shares-hub":
            case "indices-hub":
            case "etfs-hub":
            case "crypto-hub":

                $('.products-url.main-link').addClass('active');
                break;
            case "contact-us":
            case "about-us":
                $('.our-company-url.main-link').addClass('active');
                break;
            case "news":
            case "news-archive":
            case "authors":
            case "today-in-the-market":
            case "market-analysis":
            case "breaking-news":
                $('.news-url .main-link').addClass('active');
            break;
            default:
            // only if case does not exist
        }
    }

    function lazyImageBF() {
        var lazyImages = [].slice.call(document.getElementsByClassName("lazy-image-bf"));
        lazyImages.forEach.call(lazyImages, function (lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-image-bf");
        });
    }


    windowOnLoad(function () {
        let $ = window.jQuery;
        let main_header = $('.main-header');
        let body = $('body');
        let scrolling = false;


        function toggleCookieNotification() {
            $('#cookie-notif')[window.readcookie('fx_cn') ? 'hide' : 'show']();
        }

        window.savePPCookieA = function () {
            window.savecookie("fx_cn", "1");
            $('#cookie-notif').hide();
        };


        general();
        window.__dlw.init();

        window.dataLayer.push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });

        const script = document.createElement('script');
        document.body.appendChild(script);
        script.async = true;
        script.defer = true;
        script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-KBZ4Z3H';


        setActiveUrl();
        
        if (document.location.hash ) {
            var hash = document.location.hash;
            try {
                if( $(hash) && $(hash).length > 0){
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top - main_header.height()
                    }, 1e3);
                }
              }
              catch(err) {}

        }


        /*******/
        toggleCookieNotification();


        //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf
        let lazyImage_wrapper = $('.lazy-image-bf');
        let loadingLazyImagesBF = false, lazyImagesBFLoaded = false;
        let footer_wrapper = $('.footer-shared');
        let loadingFooter = false, footerLoaded = false;
        let cookie_notif = $('#cookie-notif');
        let cookie_notif_height = cookie_notif.outerHeight();
        let main_header_height = main_header.outerHeight();
        let fxEcalendarContainer = $('#fxEcalendar');


        $( window ).resize(function() {
            cookie_notif_height = cookie_notif.outerHeight();
            main_header_height = main_header.outerHeight();
		  });

        let loadingfxEcalendar = false,
            fxEcalendarLoaded = false,
            loadingLiveRates = false,
            liveRatesLoaded = false;

        let animation_banner = $('.fixed-content'), 
        social_bar = $('.li-social'),
        banner_height = $('.main-banner').height();
        


        function doScrolling() {
            if (!loadingfxEcalendar && fxEcalendarContainer.length > 0 && fxEcalendarContainer.html().trim() === '' && window.isInViewport(fxEcalendarContainer)) {
                loadingfxEcalendar = true;
                !fxEcalendarLoaded && import(/* webpackChunkName: "fxEcalendar" */'../components/fxEcalendar/fxEcalendar')
                    .then(() => {
                        loadingfxEcalendar = false;
                        fxEcalendarLoaded = true;
                        window.fxEcalendar({curr_lang: window.tm_language, importance: '3', maxRows: '6'});
                    });
            }



            //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf

            if (lazyImage_wrapper.length > 0 && !loadingLazyImagesBF && !lazyImagesBFLoaded && window.isInViewport(lazyImage_wrapper)) {
                loadingLazyImagesBF = true;
                if (!lazyImagesBFLoaded) {
                    lazyImageBF();
                    loadingLazyImagesBF = false;
                    lazyImagesBFLoaded = true;
                }
            }
            if (footer_wrapper.length > 0 && !loadingFooter && window.isInViewport(footer_wrapper)) {


                loadingFooter = true;
                !footerLoaded && import(/* webpackChunkName: "footer" */'../components/footer/footer')
                    .then(module => {
                        loadingFooter = false;
                        footerLoaded = true;
                        module.default();
                    });
            }

            if (window.readcookie('fx_cn') * 1 !== 1) {

                if ($(window).scrollTop() > 2) {
                   main_header.css('top', -(cookie_notif_height));

                } else {
                    main_header.css('top', 0);
                }
            }

            if($(window).width() > 992){
                if(banner_height > 0){
                    if( $(document).scrollTop() > banner_height && social_bar.length > 0  ){
                        social_bar.show();
                    }else{
                        social_bar.hide();
                    }
                }else{
                    social_bar.show();
                }
            }

            if(!initchat) {
                
                window.dataLayer.push({"event": "init-snapengage"});
                initchat = true;
               
            }
          
        }

        window.scrollingTasks.push(doScrolling);

        if ($(window).width() > 992) {
            lazyImageBF();
            loadingLazyImagesBF = false;
            lazyImagesBFLoaded = true;
        }

  
        function scrollingTimer() {
            if ($(document).height() <= $(window).height()) {
                scrolling = true;
            }
            if($(window).scrollTop()>1){
                scrolling = true;
            }
            if(window.callDoScrolling) scrolling = true;
            // if (scrolling) doScrolling();

            if (scrolling) window.scrollingTasks.forEach(function (task) {
                task();
            });
            window.setTimeout(scrollingTimer, 250);
        }

        scrollingTimer();

        //window.dataLayer.push({"event":"tracking-init"});


        if($(window).width() > 992  && (
            languageAttributes.spokenLanguageCode == 'es' || 
            languageAttributes.languageCode == 'hi' ||
            languageAttributes.spokenLanguageCode == 'ar')){
            initchat = true;
            window.dataLayer.push({"event": "init-snapengage"});
        }
    
        window.startChat = function(t){
            if(typeof SnapEngage !== 'undefined'){
                window.dataLayer.push({"event":"start-chat"});
                $('#SnapABug_WP').addClass('lightbox-login');
            }else{
                setTimeout(function(){ window.startChat(); }, 1000);
            }
           
        };

        $(window).on('scroll resize', function (e) {
            scrolling = true;

        });

        


    });
})(window.jQuery, window.dataLayer || []);

